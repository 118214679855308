<div class="center-container">
  <div class="logo-container">
    <img class="logo" src="assets/logo.svg" alt="logo" />
    <div class="line"></div>
    <img class="logo glasu" src="assets/logo-glasu.svg" alt="logo-glasu" />
  </div>
  <div class="name">
    <div class="text">Vendas</div>
    <div class="number">4.0</div>
  </div>
  <button (click)="loginRedirect()" mat-raised-button class="center-button">
    ENTRAR
  </button>
  <button (click)="loginManual()" mat-raised-button class="help-button">
    COMO ACESSAR
  </button>
</div>

import { ClipboardModule } from '@angular/cdk/clipboard';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import {
  FormGroupDirective,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { AlertMessageComponent } from './components/alert-message/alert-message.component';
import { ClientInfoComponent } from './components/client-info/client-info.component';
import { ClientItemComponent } from './components/client-item/client-item.component';
import { CommentCardComponent } from './components/comment-card/comment-card.component';
import { FilterAccessComponent } from './components/filter-access/filter-access.component';
import { HandsComponent } from './components/hands/hands.component';
import { LastUpdateInfoComponent } from './components/last-update-info/last-update-info.component';
import { MultiselectComponent } from './components/multiselect/multiselect.component';
import { ProductItemComponent } from './components/product-item/product-item.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { SearchboxComponent } from './components/searchbox/searchbox.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { UpsertCommentComponent } from './components/upsert-comment/upsert-comment.component';
import { AutofocusDirective } from './directive/autofocus.directive';
import { MaterialModule } from './material.module';
import { EmptyPipe } from './pipe/empty.pipe';
import { FilterListPipe } from './pipe/filter.pipe';
import { TitleCasePipe } from './pipe/title-case.pipe';

export const COMPONENT = [
  FilterAccessComponent,
  SearchboxComponent,
  ClientItemComponent,
  FilterListPipe,
  EmptyPipe,
  TitleCasePipe,
  MultiselectComponent,
  SpinnerComponent,
  ClientInfoComponent,
  ProductItemComponent,
  HandsComponent,
  ProgressBarComponent,
  LastUpdateInfoComponent,
  AlertMessageComponent,
  AutofocusDirective,
  CommentCardComponent,
  UpsertCommentComponent,
];
@NgModule({
  declarations: [...COMPONENT],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MaterialModule,
    NgOptimizedImage,
    NgSelectModule,
    FormsModule,
    RouterModule,
    ScrollingModule,
    ClipboardModule,
  ],
  exports: [
    CommonModule,
    MaterialModule,
    NgOptimizedImage,
    NgSelectModule,
    FormsModule,
    ...COMPONENT,
  ],
  providers: [FormGroupDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}

import { ItemMultiSelect } from 'src/app/shared/components/multiselect/multiselect.component';
import { Product } from '../product/product.model';

export interface ClientItem {
  name: string;
  status: string;
  id: string;
  cnpj: string;
  recommendations?: Product[];
}

export interface ClientDetail {
  id: number;
  legal_name: string;
  name: string;
  email: string;
  phone: string;
  cnpj: string;
  status: string;
  address: string;
  neighborhood: string;
  state: string;
  city: string;
}

export interface FilterClient {
  states: ItemMultiSelect[];
  cities: ItemMultiSelect[];
  neighborhoods: ItemMultiSelect[];
  selectedOption: number;
}

export interface Comment {
  id: number;
  text: string;
  edited_date?: string;
  date: string;
}

export enum DefaultClientStatus {
  text = 'private',
}

import { Component, Input } from '@angular/core';
import { Product } from 'src/app/modules/product/product.model';

@Component({
  selector: 'app-last-update-info',
  templateUrl: './last-update-info.component.html',
  styleUrls: ['./last-update-info.component.scss'],
})
export class LastUpdateInfoComponent {
  @Input() product?: Product;
}

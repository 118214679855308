/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ApiBridgeService {
  constructor(private httpClient: HttpClient) {}
  get<T>(url: string, queryParams: any = null): Observable<T> {
    let params = new HttpParams();
    if (queryParams) {
      params = Object.getOwnPropertyNames(queryParams).reduce(
        (p, key) => p.set(key, queryParams[key]),
        new HttpParams()
      );
    }
    return this.httpClient.get<T>(environment.apiConfig.uri + url, { params });
  }

  post<T>(
    url: string,
    body: any = null,
    queryParams: any = null
  ): Observable<T> {
    let params = new HttpParams();
    if (queryParams) {
      params = Object.getOwnPropertyNames(queryParams).reduce(
        (p, key) => p.set(key, queryParams[key]),
        new HttpParams()
      );
    }
    return this.httpClient.post<T>(environment.apiConfig.uri + url, body, {
      params,
      headers: {
        location: 'banana',
      },
    });
  }

  put<T>(
    url: string,
    body: any = null,
    queryParams: any = null
  ): Observable<T> {
    let params = new HttpParams();
    if (queryParams) {
      params = Object.getOwnPropertyNames(queryParams).reduce(
        (p, key) => p.set(key, queryParams[key]),
        new HttpParams()
      );
    }
    return this.httpClient.put<T>(environment.apiConfig.uri + url, body, {
      params,
    });
  }

  delete<T>(url: string, queryParams: any = null): Observable<T> {
    let params = new HttpParams();
    if (queryParams) {
      params = Object.getOwnPropertyNames(queryParams).reduce(
        (p, key) => p.set(key, queryParams[key]),
        new HttpParams()
      );
    }
    return this.httpClient.delete<T>(environment.apiConfig.uri + url, {
      params,
    });
  }
}

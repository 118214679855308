<app-header [userManual]="true"></app-header>
@if (sanitizedUrl) {
<iframe
  class="iframe-manual"
  [src]="sanitizedUrl"
  title="User Manual"
  sandbox="allow-forms allow-modals allow-orientation-lock allow-popups allow-same-origin allow-scripts"
  allowfullscreen
  mozallowfullscreen
  msallowfullscreen
  webkitallowfullscreen
></iframe>
}

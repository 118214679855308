import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterList',
})
export class FilterListPipe implements PipeTransform {
  transform(list: any[], searchText: string): any[] {
    return list.filter((result: any) => {
      if (!searchText) {
        return result;
      }
      return (
        Object.values(result).filter((value: any) =>
          value.toString().toLowerCase().includes(searchText.toLowerCase())
        ).length ?? result
      );
    });
  }
}

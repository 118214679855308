<div class="content">
  <div class="title">Oportunidade de compra:</div>
  <div class="bar">
    <mat-icon
      [inline]="true"
      class="material-icons bar-icon"
      [svgIcon]="'ic-bar-' + progress"
    >
    </mat-icon>
  </div>
</div>

<div class="comment-card">
  <p class="comment-text">{{ comment.text }}</p>
  <div class="controls">
    <span class="date">
      {{ comment.edited_date ? 'Editado em' : '' }}
      {{ (comment.edited_date ? comment.edited_date : comment.date) | date : "dd/MM/yy 'às' HH:mm 'hs'" }}
    </span>
    <div class="buttons">
      <button
        mat-button
        (click)="edit()"
        [disabled]="!(showNetworkStatus$ | async)">
        <mat-icon
          class="material-icons"
          svgIcon="ic-edit"
        ></mat-icon>
        <span>Editar</span>
      </button>
      <button
        mat-button
        (click)="delete()"
        [disabled]="!(showNetworkStatus$ | async)">
        @if (!(showNetworkStatus$ | async)) {
          <mat-icon
            class="material-icons"
            svgIcon="ic-trash-disabled"
          ></mat-icon>
        } @else {
          <mat-icon
            class="material-icons"
            svgIcon="ic-trash"
          ></mat-icon>
        }
        <span [ngStyle]="{'color': (showNetworkStatus$ | async) ? '#c72929' : '#666'}">Deletar</span>
      </button>
    </div>
  </div>
</div>

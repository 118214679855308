import { Component, Input } from '@angular/core';
import { ClientDetail } from 'src/app/modules/client/client.model';
import {Status} from "../alert-message/alert-message.component";
import {NetworkService} from "../../../core/network.service";
import {Observable} from "rxjs";

@Component({
  selector: 'app-client-info',
  templateUrl: './client-info.component.html',
  styleUrls: ['./client-info.component.scss'],
})
export class ClientInfoComponent {
  @Input() client!: ClientDetail;
  showMoreInfo: boolean = false;

  @Input() status: Status = Status.Error;
  showNetworkStatus$!: Observable<boolean>;
  statusEnum = Status;
  constructor(
    private networkService: NetworkService
  ) {
    this.showNetworkStatus$ = this.networkService.networkStatus;
  }

}

import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, merge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  private networkStatus$ = new BehaviorSubject<boolean>(navigator.onLine);
  networkStatus: Observable<boolean> = this.networkStatus$.asObservable();
  networkStatusConst: boolean = navigator.onLine;
  constructor() {
    merge(
      fromEvent(window, 'online').pipe(map(() => true)),
      fromEvent(window, 'offline').pipe(map(() => false))
    ).subscribe((status: boolean) => {
      this.networkStatusConst = status;
      this.networkStatus$.next(status);
    });
  }
}

<div class="filter-access-container">
  <div class="label">
    <span class="title">{{ title }}</span>
  </div>
  <div class="button">
    <button
      [disabled]="!(showNetworkStatus$ | async)"
      mat-raised-button
      [routerLink]="[routeAccess]"
      routerLinkActive="router-link-active"
      [class.active-filter]="activeFilter"
    >
      <span class="text-button">FILTROS</span>

      <mat-icon
        class="material-icons icon"
        [svgIcon]="activeFilter ? 'ic-check' : 'ic-filter'"
        iconPositionEnd
        [inline]="true"
      ></mat-icon>
    </button>
  </div>
</div>

import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PWAService {
  constructor(private _snackBar: MatSnackBar) {}

  deferredPrompt: any;
  private pwaSuggestion$ = new BehaviorSubject<boolean>(false);
  pwaSuggestion: Observable<boolean> = this.pwaSuggestion$.asObservable();

  initPWA(): void {
    if (window.location.href.includes('localhost')) {
      return;
    }
    window.addEventListener('beforeinstallprompt', (e: Event) => {
      e.preventDefault();
      this.deferredPrompt = e;
      setTimeout(() => {
        this.pwaSuggestion$.next(true);
      }, 5000);
    });
  }

  cancelSuggestion() {
    this.pwaSuggestion$.next(false);
    setTimeout(() => {
      this.pwaSuggestion$.next(true);
    }, 10000);
  }

  installPWA(): void {
    this.deferredPrompt.prompt();
    this.deferredPrompt.userChoice.then((choiceResult: any) => {
      this.pwaSuggestion$.next(false);
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        setTimeout(() => {
          this.pwaSuggestion$.next(true);
        }, 5000);
      }
    });
  }
}

import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { StorageKey, StorageService } from 'src/app/core/storage.service';
import { ClientItem } from 'src/app/modules/client/client.model';
import { ProductService } from 'src/app/modules/product/product.service';

@Component({
  selector: 'app-client-item',
  templateUrl: './client-item.component.html',
  styleUrls: ['./client-item.component.scss'],
})
export class ClientItemComponent {
  @Input() client?: ClientItem;

  constructor(
    private productService: ProductService,
    private routerService: Router,
    private storageService: StorageService
  ) {}
  openClient(): void {
    this.storageService.removeData(StorageKey.ProductFilter);
    this.routerService.navigate(['product/list', this.client?.id]);
  }
}

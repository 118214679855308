<app-spinner></app-spinner>
@if (loginDisplay$ | async) {
  <app-header></app-header>
}

<div>
  @if (!isIframe) {
    <router-outlet></router-outlet>
  }
</div>

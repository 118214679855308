<div class="search-container">
  <div class="input-container">
    <mat-form-field appearance="outline">
      <input
        type="text"
        #searchInput
        matInput
        (focus)="showSearchCancel = true"
        [placeholder]="placeholder"
      />
      @if (showSearchCancel) {
        <div (click)="clear()">
          <mat-icon class="delete-icon" svgIcon="ic-delete"> </mat-icon>
        </div>
      }
      @if (!showSearchCancel) {
        <mat-icon
          class="search-icon"
          matSuffix
          svgIcon="ic-search"
        >
        </mat-icon>
      }
    </mat-form-field>
  </div>
</div>

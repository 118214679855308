import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../core/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  constructor(private authService: AuthService, private router: Router) {
    document.body.style.background =
      'url(../../assets/login_background.png) no-repeat center center';
    document.body.style.backgroundSize = 'cover';
  }

  ngOnInit(): void {
    this.authService.loginDisplayObs.subscribe((loginDisplay) => {
      if (loginDisplay) {
        this.router.navigate(['/client/list']);
      }
    });
  }

  loginRedirect(): void {
    this.authService.login();
  }

  loginManual(): void {
    const link = document.createElement('a');
    link.href = 'assets/manuals/access.pdf';
    link.download = 'manual_de_acesso.pdf';
    link.click();
  }
}

<div class="filter-access-container">
  <div class="label">
    @if (product) {
    <span class="subtitle"
      >Última atualização:
      {{ product.date | date : "dd/MM/yy 'às' HH:mm 'hs'" }}</span
    >
    } @else {
    <span class="subtitle"> Última atualização: --- </span>
    }
  </div>
</div>

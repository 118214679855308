@if (product) {
<ng-container>
  <div class="item-content" [class.one-priority]="product.sell_priority === 1">
    <div
      class="priority"
      [class.one-priority-title]="product.sell_priority === 1"
    >
      Prioridade {{ product.sell_priority }}
    </div>
    <div class="title">
      {{ product.product_name }}
    </div>

    <div class="opportunity">
      <app-progress-bar [progress]="product.sell_oportunity"></app-progress-bar>
    </div>

    <div class="info">
      <div class="chips">
        <div class="chip">
          <span>{{ product.product_category | titleCase }}</span>
        </div>
        @if (product.product_type !== null) {
        <div class="chip">
          <span>{{
            product.product_type === true ? "Com máquina" : "Sem máquina"
          }}</span>
        </div>
        }
      </div>
      @if (showNetworkStatus$ | async) {
      <div class="feedback">
        <app-hands
          [product]="product"
          (handSelect)="handSelect.emit($event)"
        ></app-hands>
      </div>
      }
    </div>
  </div>
</ng-container>
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { NetworkService } from 'src/app/core/network.service';
import { Product } from 'src/app/modules/product/product.model';
import { HandFeedbackOperation } from '../hands/hands.component';

@Component({
  selector: 'app-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.scss'],
})
export class ProductItemComponent {
  @Input() product!: Product;
  @Output() handSelect = new EventEmitter<{
    handFeedbackOperation: HandFeedbackOperation;
    selection: boolean | null;
  }>();
  showNetworkStatus$!: Observable<boolean>;
  constructor(private networkService: NetworkService) {
    this.showNetworkStatus$ = this.networkService.networkStatus;
  }
}

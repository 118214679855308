@if (product) {
  <div class="content-hands">
    <div class="hand good" (click)="onClickHand(HandTypeEnum.GOOD)">
      <mat-icon
        [inline]="true"
        class="material-icons"
        [svgIcon]="goodSelected ? 'ic-hand-good-selected' : 'ic-hand-good'"
      >
      </mat-icon>
    </div>
    <div class="hand bad" (click)="onClickHand(HandTypeEnum.BAD)">
      <mat-icon
        [inline]="true"
        class="material-icons"
        [svgIcon]="badSelected ? 'ic-hand-bad-selected' : 'ic-hand-bad'"
      ></mat-icon>
    </div>
  </div>
}

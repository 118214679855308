import { Inject, Injectable } from '@angular/core';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  EventMessage,
  EventType,
  InteractionStatus,
  PublicClientApplication,
  RedirectRequest,
} from '@azure/msal-browser';
import { BehaviorSubject, Observable, filter, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  ApplicationInsightsService,
  EventName,
} from './application-insights.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private applicationInsightsService: ApplicationInsightsService
  ) {}

  private loginDisplay$ = new BehaviorSubject<boolean>(false);
  loginDisplayObs: Observable<boolean> = this.loginDisplay$.asObservable();

  init() {
    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        } else {
          this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE ||
            msg.eventType === EventType.SSO_SILENT_FAILURE ||
            msg.eventType === EventType.ACQUIRE_TOKEN_BY_CODE_FAILURE
        ),
        tap(() => {
          const msalInstance: PublicClientApplication = this.authService
            .instance as PublicClientApplication;
          this.applicationInsightsService.logEvent(EventName.ErrorTokenLogout);
          msalInstance.clearCache();
        })
      )
      .subscribe();
  }

  login(): void {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
        redirectStartPage: environment.msalConfig.redirectStartPage,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  logout() {
    this.authService.logoutRedirect();
  }

  setLoginDisplay() {
    this.loginDisplay$.next(
      this.authService.instance.getAllAccounts().length > 0
    );
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { HeaderComponent } from '../shared/components/header/header.component';

@Component({
  selector: 'app-login.manual',
  templateUrl: './login.manual.component.html',
  styleUrls: ['./login.manual.component.scss'],
  standalone: true,
  imports: [HeaderComponent],
})
export class LoginManualComponent implements OnInit {
  sanitizedUrl!: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    // Trusting the URL as it is sourced from our environment configuration,
    // which is controlled and considered safe.
    this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      environment.manuals.access
    );
  }
}
